import { isNgServe } from '@core';

import { Environment } from './type';

export const environment: Environment = {
  name: 'Oongalee ExcuseMe - Default - DEV',
  machineName: 'default.dev',
  production: true,
  serviceWorker: !isNgServe,
  debug: true,
  apiUrl: 'https://oongalee-exscuseme-srv-dev.r2.danavero.com',
  socketApi: 'wss://oongalee-exscuseme-srv-dev.r2.danavero.com',
  sentry: {
    dsn: '',
  },
  backgroundPlayer: null,
  backgroundAdPlayer: 'https://dwall-app-ad-player.netlify.app',
  backgroundAdPlayerColor: null,
  update: {
    checkStrategy: 'every-minute',
    activateStrategy: 'restart-when-idle',
  },
  featureToggle: {
    idleClickGoTo: ['/main/home'],
    withTabBar: true,
    withCallWaiter: true,
    withCallWaiterToPay: true,
    withCallWaiterToRepeat: true,
    withCallWaiterOnOffline: true,
    withInvoice: true,
    withServiceCentre: true,
    tabBarAutoHide: undefined,
    idleDelay: undefined,
    ambientLight: {
      callWaiter: null,
      callWaiterToPay: null,
      callWaiterToRepeat: null,
    },
  },
};
