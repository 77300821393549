import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

@Component({
  selector: 'app-restaurant-menu-image',
  standalone: true,
  templateUrl: './restaurant-menu-image.component.html',
  styleUrls: ['./restaurant-menu-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PinchZoomModule,
  ],
})
export class RestaurantMenuImageComponent {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  @Input()
  public url: string | SafeUrl = '';

}
