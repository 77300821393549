import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, fromEvent, interval, merge, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, skip, switchMap, tap } from 'rxjs/operators';
import {
  ActivitySectionDirective,
  CORE_FEATURE_TOGGLE,
  CoreFeatureToggle,
  InteractionService,
  NetworkService,
  RestaurantTableService,
  UserActivityClickTrackingDirective,
  WebsocketService,
  WidgetUiConfigService,
} from '@core';
import { NgIf } from '@angular/common';
import { LottieComponent } from 'ngx-lottie';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  imports: [
    NgIf,
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    LottieComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit, AfterViewInit {

  public readonly tabIndicator = {
    position: -100,
    accent: false,
  };

  public showInteractsPresent = false;

  @ViewChild('tabs')
  private tabsElement: ElementRef | undefined;

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly networkConnection: NetworkService,
    private readonly interaction: InteractionService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
  ) {}

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get userInteracts(): boolean {
    return this.interaction.interacting;
  }

  get hideTabBar(): boolean {
    if (this.widgetUiConfigService.tabBarAutoHide) {
      return this.interaction.interacting;
    }
    else {
      return true;
    }
  }

  get isOnline(): boolean {
    return this.networkConnection.status$.getValue();
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get tabIndicatorPulse(): boolean {
    return this.tabIndicator.accent && (
      this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat
    );
  }

  get withCallWaiter(): boolean {
    return this.featureToggle.withCallWaiter && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToPay(): boolean {
    return this.featureToggle.withCallWaiterToPay && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToRepeat(): boolean {
    return this.featureToggle.withCallWaiterToRepeat && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withTabBar(): boolean {
    return this.featureToggle.withTabBar;
  }

  get withInvoice(): boolean {
    return this.featureToggle.withInvoice;
  }

  get withServiceCentre(): boolean {
    return this.featureToggle.withServiceCentre;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get isHomePage(): boolean {
    return this.router.url === '/main/home';
  }

  get isMenuPage(): boolean {
    return this.router.url === '/main/menu';
  }

  get isInvoicePage(): boolean {
    return this.router.url === '/main/invoice';
  }

  get isServicePage(): boolean {
    return this.router.url === '/main/home/service';
  }

  ngOnInit(): void {
    this.initWorkerConnectionStatus();
    this.initWorkerUserInteracts();
  }

  ngAfterViewInit(): void {
    this.initWorkerIndicatorPosition();
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  navigateTo(commands: unknown[]): void {
    this.router.navigate(commands);
  }

  private initWorkerIndicatorPosition(): void {
    timer(1000).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.router.events.pipe(
      filter((event): event is NavigationStart => {
        return event instanceof NavigationStart;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((event) => {
      this.setIndicatorPositionByUrl(event.url);
    });

    fromEvent(window, 'resize').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.restaurantTableService.table$.pipe(
      pluck('widgetUIConfig'),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.router.navigate(['/main/home']);

      setInterval(() => this.setIndicatorPositionByUrl(this.router.url), 500);
    });
  }

  private initWorkerConnectionStatus(): void {
    merge(
      this.networkConnection.status$,
      this.webSocketService.status$,
    ).pipe(
      map(() => this.withCallWaiter),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.tabIndicator.accent = this.isHomePage && status;

      if (!status) {
        this.restaurantTableService.stopAllCallWaiter();
      }

      if (!status && this.isServicePage) {
        this.router.navigate(['/main/home']);
      }
    });
  }

  private initWorkerUserInteracts(): void {
    this.interaction.idle$.pipe(
      skip(1),
      tap((idle) => {
        if (!idle && this.isHomePage) {
          this.router.navigate(this.featureToggle.idleClickGoTo);
        }
      }),
      filter((idle) => idle),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      if (!this.isHomePage) {
        this.router.navigate(['/main/home']);
      }

      if (this.translate.currentLang !== this.translate.defaultLang) {
        this.translate.use(this.translate.defaultLang);
      }
    });

    merge(
      this.restaurantTableService.table$.pipe(
        map(() => (
          {
            idleDelay: this.widgetUiConfigService.idleDelay,
            tabBarAutoHide: this.widgetUiConfigService.tabBarAutoHide,
          }
        )),
        distinctUntilChanged(),
        tap(({ tabBarAutoHide }) => {
          if (!tabBarAutoHide) {
            this.showInteractsPresent = false;
          }
        }),
        map(() => this.interaction.idle),
      ),
      this.interaction.idle$,
    ).pipe(
      tap((idle) => {
        if (!idle && this.showInteractsPresent) {
          this.showInteractsPresent = false;
        }
      }),
      switchMap((idle) => {
        if (idle && this.widgetUiConfigService.tabBarAutoHide) {
          return interval(39700).pipe(
            tap(() => this.showInteractsPresent = true),
            switchMap(() => timer(9700)),
            tap(() => this.showInteractsPresent = false),
          );
        }

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private setIndicatorPositionByUrl(url: string): void {
    const element = this.getTabElementByUrl(url);

    if (element) {
      this.tabIndicator.position = element.offsetLeft + (
        element.offsetWidth / 2
      );
      this.tabIndicator.accent = element.hasAttribute('indicatorAccent');
    }
  }

  private getTabElementByUrl(url: string): HTMLElement | null {
    return this.tabsElement?.nativeElement.querySelector(`.tab[routerlink="${ url }"]`);
  }

}
