<div class="call-waiter-message" [class.active]="callWaiter || callWaiterToPay || callWaiterToRepeat">
  {{ 'common.callWaiterMessage' | translate }}
</div>

<div class="content" coreActivitySection="InvoiceButton">
  <img class="logo" [src]="widgetUi.logo" alt="" />

  <img class="qr" *ngIf="qrUrl" [src]="qrUrl" alt="QR Code">

  <div class="table">{{ tableName }}</div>

  <div *ngIf="withCallWaiterToPay && widgetUi.requestBillButtonEnabled && qrUrl" class="divider">OR</div>

  <button
    *ngIf="withCallWaiterToPay && widgetUi.requestBillButtonEnabled"
    [class.active]="callWaiterToPay"
    (click)="handlerCallWaiterToPay($event)"
    [coreUserActivityClickTracking]="callWaiterToPay ? 'CancelCallWaiterToPay' : 'StartCallWaiterToPay'"
  >
    {{ widgetUi.requestBillButtonName }}
  </button>
</div>
