<div *ngIf="isHomePage" class="special-offer-handler" (click)="specialOfferOnClick()"></div>

<app-happy-hours></app-happy-hours>

<div class="call-waiter-message" [class.active]="callWaiter || callWaiterToPay || callWaiterToRepeat">
  {{ 'common.callWaiterMessage' | translate }}
</div>

<div [@homeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
